import { Session } from 'next-auth';

export interface IDefaultPageProps {
  session: Session | null;
  error?: {
    message: string;
  }
}

export const ApolloErrorCookie = 'APOLLO_ERROR';
