import styled from '@emotion/styled';
import * as React from 'react';
import { Loading } from 'react-admin';

export const Loader: React.FC = () => {
  return (
    <LoaderContainer>
      <Loading />
    </LoaderContainer>
  );
};

const LoaderContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
